<template>
  <div>
    <h3 class="title-g">成员管理</h3>

    <div class="databox-g">
      <h4 class="btnwrap-g">添加成员</h4>
      <el-form ref="membAdminAdd" :model="membAdminAdd" label-width="80px" size="mini">
        <el-row :gutter="10">
          <el-col :span="4">
            <el-form-item label="电话">
              <el-input v-model="membAdminAdd.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="类别">
              <el-input v-model="membAdminAdd.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="姓名">
              <el-input v-model="membAdminAdd.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户名">
              <el-input v-model="membAdminAdd.userName" maxlength="12" placeholder="6~15位，只能英文和数字"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" @click="addMember">添加成员 (初始密码：123456)</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="databox-g">
      <h4 class="btnwrap-g">成员列表</h4>
      <el-table :data="membAdmin">
        <el-table-column v-for="(value, key) in tableList" :key="key" :prop="key" :label="value"></el-table-column>
        <el-table-column label="管理">
          <template slot-scope="scope">
            <el-button type="primary" @click="resetPsd(scope.row)" size="mini">重置密码</el-button>
            <el-button
              @click="deleteMemb(scope.row)"
              type="danger"
              size="mini"
              plain
              v-if="scope.row.type === '1'"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getMemberlist,
  createAdminMemb,
  deleteUser,
  memberResetPsd
} from "@/api/api";
export default {
  data() {
    return {
      tableList: {
        membId: "成员ID",
        type: "类别",
        idUser: "用户ID",
        userName: "用户名",
        name: "姓名",
        gentTime: "2020-09-01"
      },
      membAdminAdd: {
        type: "1",
        userName: "",
        name: "",
        phone: ""
      },
      membAdmin: []
    };
  },
  mounted() {
    this.getMemberlistFn();
  },
  methods: {
    getMemberlistFn() {
      const that = this;
      getMemberlist().then(res => {
        if (res.result) {
          that.membAdmin = res.data;
        }
      });
    },
    addMember() {
      const that = this;
      let pattern = /[0-9a-zA-Z]{6,15}/;
      var test = pattern.test(this.membAdminAdd.userName);
      if (
        (this.membAdminAdd.phone === "") |
        (this.membAdminAdd.type === "") |
        (this.membAdminAdd.type < 1) |
        (this.membAdminAdd.type > 2) |
        (this.membAdminAdd.name === "") |
        !test
      ) {
        this.$alert("所有项均必填,type只能1或2", {
          type: "warning",
          callback: () => {}
        });
      } else {
        //初始密码固定为123456，使用初始密码登陆时，要求修改密码
        createAdminMemb(that.membAdminAdd).then(res => {
          if (res.result) {
            that.$alert("成功添加成员", {
              type: "success",
              callback: () => {
                this.membAdminAdd.userName = "";
                this.membAdminAdd.name = "";
                this.membAdminAdd.phone = "";
                that.getMemberlistFn();
              }
            });
          } else {
            that.$alert(res.message, {
              type: "warning",
              callback: () => {}
            });
          }
        });
      }
    },
    deleteMemb(row) {
      const that = this;
      this.$confirm("确定删除该成员？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      })
        .then(() => {
          //   membId
          deleteUser({
            membId: row.membId
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "删除成功!"
              });
              that.getMemberlistFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    resetPsd(row) {
      const that = this;
      this.$confirm("确定重置密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          memberResetPsd({
            membId: row.membId
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "密码已重置为‘123456’"
              });
              that.getMemberlistFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置"
          });
        });
    }
  }
};
</script>

<style scoped>
</style>